import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Heading from 'components/Heading';
import InviteMember from 'router/subrouters/Dashboard/components/InviteMember';
import { useTranslation } from 'react-i18next';
import { useAddMemberMutation, useGetMembersQuery } from 'api/members.api';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import Pagination from 'components/Pagination';
import { PaginatedResponse } from 'types';
import { User } from 'models/User';
import { useParams } from 'hooks/useParams';
import PageLoader from 'components/PageLoader';
import MembersFilters from '../../components/MembersFilters';
import MembersPaginationInfo from '../../components/MembersPaginationInfo';
import MembersTable from '../../components/MembersTable';
import ViewGuard from 'router/components/ViewGuard';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import { InviteMemberValues } from 'router/subrouters/Dashboard/components/InviteMember/InviteMember.component';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';

import './Members.styles.scss';
import './Members.styles.responsive.scss';

type MembersProps = {
  className?: string;
};

const Members: React.FC<MembersProps> = (props) => {
  const { className } = props;
  const dispatch = useAppDispatch();

  const { params, filterBy, resetFilters, changeCurrentPage } = useParams({
    $limit: 10,
    $populate: ['schools'],
  });
  const { t } = useTranslation();

  const {
    schoolGroup: { schools, structureType },
  } = useAppSelector(authSelectors.selectUser);

  const { data, isLoading } = useGetMembersQuery(params);

  const {
    items: members,
    totalItems: totalMembers,
    ...paginationProps
  } = data || {};

  const { $where = {} } = params;

  const { role } = $where;

  const filteredRole = useMemo(() => {
    if (!role) return;
    return role['$in'][0];
  }, [role]);

  const classes = classNames('members-page', className);

  const [addMember] = useAddMemberMutation();

  const handleOnAddMember = useCallback(
    (
      values: InviteMemberValues,
      onSucces: () => void,
      onError: (errorMessage: string) => void,
    ) => {
      addMember({
        ...values,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('memberInvited')));
          onSucces?.();
        })
        .catch((error) => {
          onError?.(t(error.mesage) || error?.message);
        });
    },
    [addMember, dispatch, t],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <Heading title={t('members')}>
        <ViewGuard permission="forbid" roles={[Role.Reviewer, Role.Teacher]}>
          <InviteMember
            addMember={handleOnAddMember}
            triggerClassName="members-page__invite-members"
          />
        </ViewGuard>
      </Heading>
      <MembersFilters
        onFilter={filterBy}
        filteredBy={$where}
        onReset={resetFilters}
        className="members-page__filters"
        filterParams={undefined}
      />
      <MembersPaginationInfo
        className="members-page__pagination-info"
        numberOfSchools={schools?.length}
        numberOfMembers={totalMembers}
        filteredRole={filteredRole}
        isStandalone={structureType === SchoolGroupStructureType.STANDALONE}
      />
      {totalMembers > 0 ? (
        <MembersTable
          className="members-page__table"
          members={members}
          schoolName={
            structureType === SchoolGroupStructureType.STANDALONE &&
            schools[0].name
          }
        />
      ) : (
        <ViewGuard permission="forbid" roles={[Role.Reviewer, Role.Teacher]}>
          <div className="empty-state-members">
            <p className="title">{t('Members.emptyState.title')}</p>
            <p className="description">{t('Members.emptyState.subtitle')}</p>
            <InviteMember addMember={handleOnAddMember} />
          </div>
        </ViewGuard>
      )}
      {(paginationProps as PaginatedResponse<User>)?.totalPages > 1 && (
        <Pagination
          onChange={changeCurrentPage}
          {...(paginationProps as PaginatedResponse<User>)}
        />
      )}
    </div>
  );
};

export default Members;
